<template>
<sub-page>
  <h1>Stats</h1>
</sub-page>
</template>

<script>
export default {
  name: "adminCrmBusinesses"
}
</script>

<style scoped>

</style>